<template>
  <section class="services-descr">
    <div class="container">
      <div class="services-descr__inner">
        <div class="services-descr__info">
          <div class="services-descr__title main__title">
            Наши услуги
          </div>
          <div class="services-descr__text main__text">
            Инспекторская служба ТЭЦ предоставляет полный комплекс услуг водителям<br> колесного транспорта
            и судовладельцам, обеспечивая экспертную диагностику<br> автомобилей, проведение технического
            осмотра морских судов и обслуживание<br> гидротехнических сооружений.
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    name: 'ServicesDescr'
}
</script>
